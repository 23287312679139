import React from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Home from "./pages/Home";
import Dashboard from "./pages/Dashboard";
import Navigation from "./components/navigation/Navigation.js";
import { Routes, Route } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import Loading from "./components/Loading";
import Admin from "./pages/Admin";
import ScannedTree from "./pages/ScannedTree";
import Footer from "./components/footer/Footer";
import ClaimTree from "./pages/ClaimTree";
import GenerateCode from "./pages/GenerateCode";

function App() {
	const { isLoading } = useAuth0();

	return (
		<div>
			{isLoading ? (
				<Loading />
			) : (
				<React.Fragment>
					<Navigation />
					<Routes>
						<Route path="/" element={<Home />}></Route>
						<Route path="/dashboard" element={<Dashboard />}></Route>
						<Route path="/admin" element={<Admin />}></Route>
						<Route path="/:userID/:treeID" element={<ScannedTree />}></Route>
						<Route path="/claim/tree" element={<ClaimTree />}></Route>
						<Route path="/generate/code" element={<GenerateCode />}></Route>
					</Routes>
					<Footer />
				</React.Fragment>
			)}
		</div>
	);
}

export default App;
