import React from 'react'
import './ButtonTemplate.css'

/**
 * This is a template of the button used on the entire Carbonaut site, 
 * with modifications made as necessary.
 * 
 * @param {Object} properties destructured object containing all the child properties and arguments 
 * @returns {HTMLElement}
 */
const ButtonTemplate = ({ children, ...args }) => {
  return (
    <button {...args}>
        {children}
    </button>
  )
}

export default ButtonTemplate