import React from "react";
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer,
} from "recharts";
import "./ProjectionMap.css";

const ProjectionMap = ({ totalTrees }) => {
    const calculateData = () => {
        let [dataArr, year, month, exponential] = [[], 2023, 0, 0];

        while (month < 12) {
            dataArr.push({
                date: year + "/" + (month + 1),
                lbs: Math.round(totalTrees * Math.exp(exponential) * 100),
            });

            month += 1;
            exponential += 0.185;
        }

        return dataArr;
    };

    return (
        <ResponsiveContainer
            className="projection-chart"
            width={"110%"}
            height={250}
            debounce={1}
        >
            <LineChart data={calculateData()}>
                <Line type="monotone" dataKey="lbs" stroke="#8884d8" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
            </LineChart>
        </ResponsiveContainer>
    );
};

export default ProjectionMap;
