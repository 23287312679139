import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import ButtonTemplate from "../button-template/ButtonTemplate";
import Form from "react-bootstrap/Form";

const ProfileForm = ({ userDetails, onSave, show, handleClose }) => {
  const [name, setName] = useState(userDetails.name);
  const [email, setEmail] = useState(userDetails.email);
  const [walletAddress, setWalletAddress] = useState(userDetails.walletAddress);

  const handleSave = () => {
    onSave({ name, email, walletAddress });
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Profile</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group>
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Group>
          {/* <Form.Group>
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group> */}
          <Form.Group>
            <Form.Label>Wallet Address</Form.Label>
            <Form.Control
              type="text"
              value={walletAddress}
              onChange={(e) => setWalletAddress(e.target.value)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <ButtonTemplate onClick={handleSave}>
          Save
        </ButtonTemplate>
      </Modal.Footer>
    </Modal>
  );
};

export default ProfileForm;