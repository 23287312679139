import React from 'react'
import './Footer.css'
import Button from '../button-template/ButtonTemplate'

const Footer = () => {
  return (
    <div className="cc-cta">
      <div className="container">
        <div className="cta-wrap">
          <div>
            <div className="cta-text">
              <div className="heading-jumbo-small">Got Questions?</div>
              <div className="paragraph-bigger cc-bigger-light">If you have any questions about carbon sequestration technologies, CODEX Token or our plans with MoonTrees, please check out our FAQ page for more information. </div>
            </div>
            <a href="https://cascadiacarbon.com/faq.html">
              <Button>
                Take me there!
              </Button>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer