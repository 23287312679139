import {
    getStorage,
    ref,
    uploadBytesResumable,
    deleteObject,
    listAll
} from "firebase/storage";
import { v4 as treeName } from "uuid";

const storage = getStorage();

export function ImageUploader(UUID, setUploadProgress) {
    console.log(UUID);
    const file = document.querySelector("input[type=file]").files[0];
    const storageRef = ref(storage, `${UUID}/${treeName()}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
        "state_changed",
        (snapshot) => {
            setUploadProgress(
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
        },
        (error) => {
            // Handle unsuccessful uploads
        }
    );
    setUploadProgress(0);
}

export function DeleteImage(UUID) {
    const listRef = ref(storage, `${UUID}`);

    listAll(listRef)
        .then((res) => {
            res.items.forEach((itemRef) => {
                // All the items under listRef.
                deleteObject(itemRef)
                .then(() => {
                    console.log("File deleted.");
                })
                .catch((error) => {
                    console.log(error);
                });
            });
        })
        .catch((error) => {
            // Uh-oh, an error occurred!
        });
}
