import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { doc, getDoc } from "@firebase/firestore";
import { db } from '../configs/Firebase'
import Loading from '../components/Loading';
import { Map, Marker } from "pigeon-maps"
import './ScannedTree.css';

const ScannedTree = () => {
  const { userID, treeID } = useParams();
  const [currentUser, setCurrentUser] = useState(null);
  const [scannedTree, setScannedTree] = useState(null);

  const getUserData = async () => {
    const documentReference = doc(db, "users", userID);
    const getDocument = await getDoc(documentReference);

    if (getDocument.exists()) {
        console.log("Document data:", getDocument.data());
        getDocument.data().nftreeInfo.forEach((tree) => {
          if (tree.id == treeID) {
            setScannedTree(tree);
          }
        })
        setCurrentUser(getDocument.data());
    } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
    }
  }

  useEffect(() => {
    getUserData();
  }, [])

  const getFormattedCoordinates = (coordinates) => {
    const cleanup = coordinates.replace(/[`()\{\}\[\]\\\/]/gi, '');
    return [Number(cleanup.split(',')[0]), Number(cleanup.split(',')[1])]
  }

  const getApproxCoordinates = (coordinates) => {
    const cleanup = coordinates.replace(/[\[\],']+/g,'');
    const split = cleanup.split(/(?<=^\S+)\s/);
    return (Math.round(parseFloat(split[0]) * 1000) / 1000) + "~, " + (Math.round(parseFloat(split[1]) * 1000) / 1000) + "~";
  }


  return (
    <div id='qr-panel'>
      <div className='qr-heading'>
        <h1>Scanned NFTree</h1>
      </div>
      <hr />
      {currentUser && scannedTree ? (
        <div className='qr-details'>
          <div>
              <img src={`https://qrcode.tec-it.com/API/QRCode?size=medium&dpi=120&data=https://carbonaut.app/${userID}/${treeID}`} alt='QR Code' className='qr-code'/>
          </div>
            <div className='tree-details'>
              <h2>Owned By: </h2>
              {currentUser.userDetails.name ? currentUser.userDetails.name : "Not Available"}
              <h2>Email: </h2>
              {currentUser.userDetails.email ? currentUser.userDetails.email : "Not Available"}
              <h2>Species:</h2>
              {scannedTree.species ? scannedTree.species : "Not Available"}
              <h2>Maturity:</h2>
              {scannedTree.maturity ? scannedTree.maturity : "Not Available"}
              <h2>Height:</h2>
              {scannedTree.height ? scannedTree.height : "Not Available"}
              <h2>Diameter:</h2>
              {scannedTree.diameter ? scannedTree.diameter : "Not Available"}
              <h2>Coordinates:</h2>
              "Currently Unavailable"
              {/* {scannedTree.coordinates ? getApproxCoordinates(scannedTree.coordinates) : "Not Available"} */}
              <h2>OpenSeaLink:</h2>
              {scannedTree.openSeaLink ? scannedTree.openSeaLink : "Not Available"}
              <h2>Health:</h2>
              {scannedTree.health ? scannedTree.health : "Not Available"}
            </div>
            <div className='tree-map'>
              <Map  height={550} defaultCenter={getFormattedCoordinates(scannedTree.coordinates)} defaultZoom={7}>
                {scannedTree.coordinates ? <Marker width={50} anchor={getFormattedCoordinates(scannedTree.coordinates)} key={scannedTree.id}/> : "Coordinates Not Availble"}
              </Map>
            </div>
        </div>
      ) : <Loading />}
    </div>
  )
}

export default ScannedTree