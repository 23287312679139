import { getDoc, doc, updateDoc } from "@firebase/firestore";
import { db } from '../configs/Firebase';
import { v4 as uuidv4 } from "uuid";

export async function addTree(AUTHID, UUID, treeRef, treeData = null) {
    const userRef = doc(db, "users", AUTHID);
    const fetchUser = await getDoc(userRef);
    const userData = fetchUser.data();

    const newTree = {
        id: treeData ? uuidv4() : UUID,
        name: treeData ? treeData.name || '' : treeRef["treeName"].current.value,
        species: treeData ? treeData.species || '' : treeRef["species"].current.value,
        height: treeData ? treeData.height || '' : treeRef["height"].current.value + treeRef["heightUnit"].current.value,
        diameter: treeData ? treeData.diameter || '' : treeRef["diameter"].current.value + treeRef["diameterUnit"].current.value,
        maturity: treeData ? treeData.maturity || '' : treeRef["maturity"].current.value,
        coordinates: treeData ? treeData.coordinates || '0.00, 0.00' : treeRef["coordinates"].current.value,
        openSeaLink: treeData ? treeData.openSeaLink || '' : treeRef["openSeaLink"].current.value,
        health: treeData ? treeData.health || '' : treeRef["health"].current.value,
        isVerified: "unknown",
        addedOn: new Date().toLocaleString(),
        paymentInfo: {
            amount: 0,
            date: "",
            referenceNumber: 0,
            paid: false,
            sessionID: ""
        },
        wasClaimed: treeData ? true : false,
        claimID: treeData ? treeData.claimID : ''
    };

    userData.nftreeInfo.push(newTree);
    userData.totalTrees++;
    if (treeRef && treeRef["walletAddress"]) {
        userData.userDetails.walletAddress = treeRef["walletAddress"].current.value;
    }

    await updateDoc(userRef, userData);
}