import { doc, setDoc } from "firebase/firestore";
import { db } from '../configs/Firebase';

export async function createUser(AuthID, name, email, emailVerified, pictureLink) {
    await setDoc(doc(db, "users", AuthID), {
        nftreeInfo: [],
        totalTrees: 0,
        userDetails: {
          name: name,
          email: email, 
          emailVerified: emailVerified,
          walletAddress: "",
          createdOn: Date().toLocaleString(),
          picture: pictureLink
        }
    });
}