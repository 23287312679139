import { useState, useContext } from "react";
import { AuthContext, UserContext } from "../../pages/Dashboard";
import ButtonTemplate from "../button-template/ButtonTemplate";
import SectionTemplate from "../section-template/SectionTemplate";
import "./Profile.css";
import ProfileForm from "../forms/ProfileForm";
import { updateUser } from "../../operations/UpdateUser";

const Profile = () => {
  const { sub } = useContext(AuthContext);
  const { userDetails } = useContext(UserContext);

  const [showEditModal, setShowEditModal] = useState(false);

  const openEditModal = () => {
    setShowEditModal(true);
  };

  const closeEditModal = () => {
    setShowEditModal(false);
  };

  const handleSave = async (updatedData) => {
    try {
      await updateUser(sub, updatedData);
      closeEditModal();
    } catch (error) {
      console.error("Unable to edit the user profile.");
    }
  };

  return (
    <SectionTemplate title="Profile">
      <div className="profile-edit">
        <ButtonTemplate onClick={openEditModal}>Edit</ButtonTemplate>
      </div>

      <div className="picture-div">
        <img
          src={userDetails.picture}
          className="rounded-circle profile-picture"
          alt="Profile"
        />
      </div>

      <div className="profile-information">
        <h4 className="text-decoration">Name</h4>
        {userDetails.name}

        <h4 className="text-decoration">Email</h4>
        {userDetails.email}

        <h4 className="text-decoration">Wallet Address</h4>
        {userDetails.walletAddress ? userDetails.walletAddress : "Unavailable"}

        <h4 className="text-decoration">Verified</h4>
        {userDetails.emailVerified ? "Yes" : "No"}
      </div>

      <ProfileForm
        userDetails={userDetails}
        onSave={handleSave}
        show={showEditModal}
        handleClose={closeEditModal}
      />
    </SectionTemplate>
  );
};

export default Profile;
