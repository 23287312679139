import { db } from "../configs/Firebase";
import { collection, query, where, getDocs, updateDoc, doc } from "@firebase/firestore";
import { addTree } from "../operations/AddTree";

export const importClaimedTrees = (user) => {
	const getClaimedTrees = async (email) => {
		try {
			const q = query(collection(db, "treeCodes"), where("email", "==", email), where("claimed", "==", true), where("imported", "==", false));

			const querySnapshot = await getDocs(q);
			const claimedTrees = querySnapshot.docs;

			// Process each claimed tree
			for (const document of claimedTrees) {
				const tree = document.data();
				const treeData = {
                    species: tree.species,
                    height: tree.height,
                    maturity: tree.maturity,
                    health: tree.health,
					claimID: document.id
                };
                
                await addTree(user.sub, null, null, treeData);

                // Update the tree in the treeCodes collection to mark it as imported
                const treeRef = doc(db, "treeCodes", document.id);
                await updateDoc(treeRef, { imported: true });
			}
		} catch (error) {
			console.error("Error getting claimed trees: ", error);
		}
	};

    if (user) {
        getClaimedTrees(user.email);
    }
};