import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API,
    authDomain: "carbonaut-dev.firebaseapp.com",
    projectId: "carbonaut-dev",
    storageBucket: "carbonaut-dev.appspot.com",
    messagingSenderId: "393932320350",
    appId: "1:393932320350:web:abdf3d0cacd1d340f58ecf",
    measurementId: "G-N3DTH038YK"
};

const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);